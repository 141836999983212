const REACT_APP_SANDBOX_BACKEND = process.env.REACT_APP_SANDBOX_BACKEND
const REACT_APP_MOCK_SERVER = process.env.REACT_APP_MOCK_SERVER

export const env={
    sandBox:REACT_APP_SANDBOX_BACKEND,
    mockServer:"REACT_APP_MOCK_SERVER"
}

// export const env={
//     sandBox:"http://localhost:4000",
//     mockServer:"http://localhost:5500"
// }